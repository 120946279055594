// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$l-blue-pallete: (
	50: #2f80ed,
	100: #2f80ed,
	200: #2f80ed,
	300: #589aff,
	400: #2f80ed,
	500: #2f80ed,
	600: #2f80ed,
	700: #003592,
	800: #002076,
	contrast: (
		50: #ffffff,
		100: #ffffff,
		200: #ffffff,
		300: #ffffff,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #ffffff,
		A200: #ffffff,
		A400: #ffffff,
		A700: #ffffff,
	),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gpt-river-frontend-primary: mat.define-palette($l-blue-pallete);
$gpt-river-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gpt-river-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gpt-river-frontend-theme: mat.define-light-theme(
	(
		color: (
			primary: $gpt-river-frontend-primary,
			accent: $gpt-river-frontend-accent,
			warn: $gpt-river-frontend-warn,
		),
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gpt-river-frontend-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
	margin: 0px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
body,
span,
div {
	font-family: 'Lato', sans-serif;
}

// Additional Eye icon for password fields should not be visible
input[type='password']::-ms-reveal {
	display: none;
}

.color-picker .saturation-lightness {
	height: 75px !important;
}
